@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.ImageViewer {}

.ImageViewer_slide {
  composes: Hero_imageContainer from "./Hero.module.postcss";
}

.ImageViewer_slide__multiple {
  height: unset;
  padding: 0 0.5rem;

  .ImageViewer_image {
    position: static;
    padding-bottom: 100%;
  }
}

.ImageViewer_image {
  composes: Hero_image from "./Hero.module.postcss";
}

.ImageViewer :global(.slick-dots) {
  list-style-type: none;
  margin-top: 1rem;

  li:only-child {
    display: none;
  }

  li {
    margin-left: 0;
    display: inline-block;
    margin-right: 0.5rem;

    button {
      background: $yellow;
      border: none;
      border-radius: 50%;
      text-indent: -9999px;
      padding: 0;
      width: 20px;
      height: 20px;
      cursor: pointer;

      &:active, &:focus {
        outline: none;
      }
    }

    &:global(.slick-active) {
      button {
        background: $blue;
      }
    }

  }
}

:global(.slick-arrow) {
  background-color: white;
  background-image: url('/static/images/arrow.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40%;
  bottom: -1.4rem;
  border: 1px solid $grey;
  cursor: pointer;
  font-size: 0;
  height: 2.75rem;
  outline: 0;
  position: absolute;
  right: 2rem;
  width: 2.75rem;
  z-index: 1;
}

:global(.slick-prev) {
  border-left-width: 0;
  right: 4.75rem;
  transform: rotate(180deg);
}

:global(.slick-disabled) {
  background-image: url('/static/images/arrow-disabled.png');
}

.VendorsToChooseFrom {
  :global(.slick-arrow) {
    bottom: 2rem;
  }

  .ImageViewer_slide {
    height: unset;
  }

  :global(.slick-slide):nth-child(even) .ImageViewer_slide {
    padding-left: 0.5rem;
  }

  :global(.slick-slide):nth-child(odd) .ImageViewer_slide {
    padding-right: 0.5rem;
  }
}